import axios from 'axios';
import Api from '../config.json';
import store from '../store'
import {
    showAlert,
    hideAlert,
    navUrl
} from '../actions/common_actions'
import {
    requestCurrentState,
    onLogout,
    requestAuthKey,
    SetNewToken
} from '../actions/login_actions';
import moment from 'moment';

// const currentState = store.getState()
// let state = store.getState();
// var auth = state.login.auth;

// function getAuthKey(){
// console.log(store.dispatch(requestAuthKey()))
// return store.dispatch(requestAuthKey());
// }

const setNewLoginToken = (token) => {
    store.dispatch(SetNewToken(token));
}

export async function postRequestWithoutAuth(url, data) {
    var d = await axios.post(Api.externals.serverUrl + url, data)
        .then(response => {
            var res = response.data
            var t = {};
            // console.log(res)
            var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
            store.dispatch(requestCurrentState())
            store.dispatch(showAlert(message, res.status))
            setNewLoginToken(res.token)
            if (res.status === "success" || res.status === "info") {
                // console.log('hari')
                t = {
                    type: "success",
                    res
                };
            } else {
                t = {
                    type: 'failed',
                    res: {
                        status: 'error'
                    }
                }
            }

            setTimeout(() => store.dispatch(hideAlert()), 3000);
            return t
        })
        .catch(err => {
            console.log(err);
            return {
                type: "failed",
                res: {
                    status: 'error'
                }
            };
        })
    return d
}

export async function postRequest(url = '/', data = [], timeout=300000) {
    var cState = store.getState();
    var auth = cState.login.auth;
    if (auth !== "" && auth !== undefined && auth !== null) {
        var d = await axios.post(Api.externals.serverUrl + url, data, {
                headers: {
                    'Authorization': "Bearer " + auth
                },
                timeout: timeout
            })
            .then(response => {
                var res = response.data
                // console.log(res.message, res.status, "Harish");
                if (res.code === 200) {
                    var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
                    store.dispatch(requestCurrentState())
                    store.dispatch(showAlert(message, res.status))
                    setNewLoginToken(res.token)

                    var f = {};

                    if (res.status === "success" || res.status === "info") {
                        f = {
                            type: "success",
                            res
                        };
                    } else if (res.status === "warning" && res.type === "unauthorized") {
                        localStorage.removeItem("authkey");
                        store.dispatch(onLogout())
                        store.dispatch(navUrl("/"))
                        f = {
                            type: 'failed',
                            res: {
                                status: 'error',
                                message : message
                            }
                        }
                        setTimeout(() => { store.dispatch(hideAlert()); window.location.reload() }, 1000);
                    } else {
                        f = {
                            type: 'failed',
                            res: {
                                status: 'error'
                            }
                        }
                    }
                    setTimeout(() => store.dispatch(hideAlert()), 5000);
                    return f;
                }
            })
            .catch(err => {
                console.log(err);
                console.log(err.code);
                return {
                    type: "failed",
                    res: {
                        status: 'error'
                    }
                };
            })
        return d
    } else {
        // return setTimeout(() => postRequest(url, data), 1000)
        console.log("Auth Not Available")
        // setTimeout(() => getRequest(url),1000);
        // getRequest(url);
        // setTimeout(() => getRequest(url), 2000);
        setTimeout(() => {
            console.log("Redirecting...")
            postRequest(url, data)
        }, 100)
    }
}


export async function getRequest(url = '/') {
    var cState = store.getState();
    var auth1 = cState.login.auth;
    if (auth1 !== "" && auth1 !== undefined && auth1 !== null) {
        var d = await axios.get(Api.externals.serverUrl + url, {
                headers: {
                    'Authorization': "Bearer " + auth1
                }
            })
            .then(response => {
                // console.log(response);
                var res = response.data
                if (res.code === 200) {
                    var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
                    store.dispatch(requestCurrentState())
                    store.dispatch(showAlert(message, res.status))
                    setNewLoginToken(res.token)

                    var f = {};

                    if (res.status === "success" || res.status === "info") {
                        f = {
                            type: "success",
                            res
                        };
                    } else if (res.status === "warning" && res.type === "unauthorized") {
                        localStorage.removeItem("authkey");
                        store.dispatch(onLogout())
                        store.dispatch(navUrl("/"))
                        f = {
                            type: 'failed',
                            res: {
                                status: 'error'
                            }
                        }
                        setTimeout(() => { store.dispatch(hideAlert()); window.location.reload() }, 1000);
                    } else {
                        f = {
                            type: 'failed',
                            res: {
                                status: 'error'
                            }
                        }
                    }
                    setTimeout(() => store.dispatch(hideAlert()), 2000);
                    return f;
                }
            })
            .catch(err => {
                console.log(err);
                console.log(err.code);
                return {
                    type: "failed",
                    res: {
                        status: 'error'
                    }
                };
            })
        return d
    } else {
        console.log("Auth Not Available")
        // setTimeout(() => getRequest(url),1000);
        // getRequest(url);
        // setTimeout(() => getRequest(url), 2000);
        setTimeout(() => {
            console.log("Redirecting...")
            getRequest(url)
        }, 100)
        // return {
        //     type: 'failed',
        //     res: {
        //         status: 'error'
        //     }
        // }
    }
}

export async function postRequestWithProvidedHeader(url, data, header) {
    // console.log(url, data, header);
    var d = await axios.post(Api.externals.serverUrl + url, data, {
            headers: header
        })
        .then(response => {
            var res = response.data
            // console.log(res.status, res.message, "Harisshh")
            if (res.status === "success" || res.status === "info") {
                var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
                store.dispatch(requestCurrentState())
                store.dispatch(showAlert(message, res.status))
                setNewLoginToken(res.token)
                setTimeout(() => store.dispatch(hideAlert()), 3000);
                return {
                    type: "success",
                    res
                };
            } else {
                var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
                store.dispatch(requestCurrentState())
                store.dispatch(showAlert(message, res.status))
                setTimeout(() => store.dispatch(hideAlert()), 3000);
                return {
                    type: 'failed',
                    res: {
                        status: 'error'
                    }
                }
            }
        })
        .catch(err => {
            console.log(err);
            return {
                type: "failed",
                res: {
                    status: 'error'
                }
            };
        })
    return d
}


export async function getRequestWithoutAuth(url) {

    ////// IF I Want loader I'll trigger it here
    ////// IF I Want loader I'll trigger it here
    ////// IF I Want loader I'll trigger it here
    ////// IF I Want loader I'll trigger it here

    var d = await axios.get(Api.externals.serverUrl + url)
        .then(response => {
            var res = response.data
            if (res.status === "success") {
                var message = typeof res.message === "object" ? res.message.message ? res.message.message : 'Problem With Connecting Data Server' : res.message;
                store.dispatch(requestCurrentState())
                store.dispatch(showAlert(message, res.status))
                setNewLoginToken(res.token)
                setTimeout(() => store.dispatch(hideAlert()), 3000);
                return {
                    type: "success",
                    res
                };
            } else {
                store.dispatch(requestCurrentState())
                store.dispatch(showAlert(message, res.status))
                setTimeout(() => store.dispatch(hideAlert()), 3000);
                return {
                    type: 'failed'
                }
            }
        })
        .catch(err => {
            console.log(err);
            return {
                type: "failed"
            };
        })

    ////// IF I Want loader I'll stop it here
    ////// IF I Want loader I'll stop it here
    ////// IF I Want loader I'll stop it here
    ////// IF I Want loader I'll stop it here
    return d
}

export function getDateFormat()
{
    return "YYYY-MM-DD";
}

export function getHumanReadableDateFormat()
{
    return "DD-MM-YYYY";
}

export function getHumanReadableDateTimeFormat()
{
    return "DD-MM-YYYY HH:mm:ss";
}

export function getDateTimeFormat()
{
    return "DD-MM-YYYY hh:mm:ss";
}

export function checkValidDate(date)
{
    var D = moment(date);
    return D.isValid();
}

/// New Function
export function getPreviousDate(date = new Date())
{
    return moment(date).subtract(1, 'day').format(getDateFormat())
}

export function getPreviousDateInReadableFormat(date = new Date())
{
    return moment(date).subtract(1, 'day').format(getHumanReadableDateFormat())
}

export function getLast60Days(date = new Date())
{
    return moment(date).subtract(60, 'day').format(getDateFormat())
}

export function getStandardDate(date = new Date())
{   
    return moment(date).format(getDateFormat());
}


export function getHumanReadableDate(date = new Date())
{   
    return moment(date).format(getHumanReadableDateFormat());
}

export function getHumanReadableDateTime(date = new Date())
{   
    return moment(date).format(getHumanReadableDateTimeFormat());
}


export function getStandardDateTime(date = new Date())
{   
    return moment(date).format(getDateTimeFormat());
}


export function getCurrentDate()
{
    // console.log(moment(new Date()).format(getDateFormat()), "Harish");
    // var date = moment(new Date()).format(getDateFormat());
    var date = moment().format(getDateFormat());
    return date;
}


export function subtractDays(n=1,type="days")
{
    var date = moment().format(getDateFormat());
    
    return moment(date).subtract(n, type).format(getDateFormat());
}

export function addDays(n=1,type="days")
{
    var date = moment().format(getDateFormat());
    
    return moment(date).add(n, type).format(getDateFormat());
}


export function getDifferentBetweenTwoDate(start=new Date(),end=new Date())
{
    // var date = moment().format(getDateFormat());
    
    return moment(start).diff(end,'minutes');
}

export function getStartDateOfMonth(date = new Date())
{
    return moment(date).startOf('month').format(getDateFormat());
}

export function getEndDateOfMonth(date = new Date())
{
    return moment(date).endOf('month').format(getDateFormat());
}

export function getYearFromDate(date = new Date())
{
    return moment(date,getDateFormat()).year();
}


export const groupArrayBy = (data,key) => {
    // `data` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    
    // var summingProps = [];
    // var fields = [];
    // Object.entries(data[0]).map((obj, objInd) => {
    //     if (obj[0] !== key) {
    //         if (typeof obj[1] === "number" || typeof obj[1] === "bigint") {
    //             summingProps.push(obj[0]);
    //         } else {
    //             fields.push(obj[0]);
    //         }
    //     }
    // })

    return data.reduce(function (storage, item) {
        // get the first instance of the key by which we're grouping
        var group = item[key];

        // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
        storage[group] = storage[group] || [];

        // add this item to its group within `storage`
        storage[group].push(item);
        // storage[group] = sumArray(storage[group]);
        // return the updated storage to the reduce function, which will then loop through the next 
        return storage;
    }, {}); // {} is the initial value of the storage
}

// convert number into INR format

export function convertNumberIntoINR(number) {
    var num = number.toString(),
        p = num
            .split("")
            .reverse()
            .map(function (v, i) {
                // remove preceeding commas
                if (i > 0 && i % 3 === 0) {
                    return v + ",";
                } else {
                    return v;
                }
            
                // return i > 0 && i % 2 === 0 ? "," + v : v;
            }
            )
            .reverse()
            .join("");
    return p;
}

export function convertToINR(number){
    let num = number.toLocaleString("en-IN", {style: "currency", currency: "INR"})
    return num
}

export function getCurrentDateINDDMMYYY(date){
    let month = date.getMonth() + 1
    let day = date.getDate()
    let year = date.getFullYear()

    return {day}/{month}/{year}
}

// export function formatDate(dateString) {
//     const date = new Date(dateString);
//     date.setHours(date.getHours() + 5);
//     date.setMinutes(date.getMinutes() + 30);
//     const year = date.getUTCFullYear();
//     const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
//     const day = ("0" + date.getUTCDate()).slice(-2);
//     const hours = ("0" + date.getUTCHours()).slice(-2);
//     const minutes = ("0" + date.getUTCMinutes()).slice(-2);
//     const seconds = ("0" + date.getUTCSeconds()).slice(-2);
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// }
export function formatDate(dateString) {
    const dateWithoutTimezone = dateString.split(" -")[0];
    const date = new Date(dateWithoutTimezone);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

export function filterDatesByRange(dateStrings, fromDate, toDate) {
    console.log(fromDate, toDate)
    const from = new Date(fromDate).getTime();
    const to = new Date(toDate).getTime() + 86400000
    return dateStrings.filter(dateString => {
      const date = new Date(dateString).getTime();
      return date >= from && date <= to;
    });
}

export function getCurrentDate1 () {
    const date = new Date()

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}-${month}-${year}`
}

export function get45DaysBefore()  {
    const date = new Date()

    date.setDate(date.getDate() - 45)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}-${month}-${year}`
}

export function getDateDuration(){
    return `Showing data for the period from ${get45DaysBefore()} to ${getCurrentDate1()}`
}

export function getSystemDateFormat(){
    return "YYYY-MM-DD hh:mm:ss"
}

export function getYYYYFormat(date = new Date()){
    return moment(date).format(getSystemDateFormat())
}

