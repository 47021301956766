import React, { Fragment, useEffect, useState } from 'react';
import { getRequest } from '../../globalhelper/helper';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';

const Input = ({ label, type, name, value, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
      </Form.Group>
    </div>
  );
};

const PaymentReport = () => {
  const [tableData, setTableData] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    company: '',
    fromDate: '',
    toDate: ''
  });

  useEffect(() => {
    getRequest('api/edureka/getCompanyAssignedToUser').then((response) => {
      const responseData = response.res.data;
      const orgname = responseData.map((item) => item.orgname);
      setCompanyDetails(responseData);
      setCompanyName(orgname);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleReset = () => {
    setFormData({
      fromDate: '',
      toDate: '',
      company: ''
    });
    setTableData([]);
    setSubmitted(false);
  };

  const handleSubmit = () => {
    const { fromDate, toDate, company } = formData;

    if (fromDate && toDate && company) {
      setLoading(true);
      setSubmitted(true);

      const id = companyDetails.filter((item) => item.orgname === company);
      const companyid = id.map((item) => item.orgid);

      getRequest(`api/edureka/getAReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`)
        .then((response) => {
          const responseData = JSON.parse(response.res.data);
          console.log("response",responseData)
          setLoading(false);
          if (responseData.length) {
            setTableData(responseData);
          } else {
            setTableData([]); // Set empty if no data is found
            toast.warning('No data found, please try some other combination');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.warning('Oops something went wrong');
        });
    } else {
      toast.error('Please select all the required fields', { theme: 'colored' });
    }
  };

  return (
    <Fragment>
      <form>
        <div className='row'>
          <div className='col-md-3'>
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                <option value='' disabled>
                  Select
                </option>
                {companyName.sort().map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Group>
          </div>

          <div className='col-md-3'>
            <Input type='date' label='Period From' name='fromDate' value={formData.fromDate} onChange={handleChange} />
          </div>

          <div className='col-md-3'>
            <Input type='date' label='Period To' name='toDate' value={formData.toDate} onChange={handleChange} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Button size='sm' variant='success' onClick={handleSubmit}>
            Submit
          </Button>
          <Button size='sm' variant='danger' onClick={handleReset} style={{ marginLeft: '10px' }}>
            Reset
          </Button>
          {tableData.length ? (
            <CSVLink data={tableData} filename='ItemList_Report.csv' style={{ marginLeft: '10px' }}>
              Download
            </CSVLink>
          ) : null}
        </div>
      </form>

      {loading ? (
        <div className='center'>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
          <div className='wave'></div>
        </div>
      ) : submitted && !loading && tableData.length > 0 ? (
        <div>
          <table border='1' id='table-css'>
            <thead>
              <tr>
              <th>PartyLedgerName</th>
                <th>DocumentNumber</th>
                <th>InvoiceRef</th>
                <th>InvoiceDate</th>
                <th>AccountingDate</th>
                <th>ExchangeRate</th>
                <th>TransactionCurrency</th>
                <th>DocumentType</th>
                <th>Ledger</th>
                <th>FPF</th>
                <th>FPT</th>
                <th>VendorID</th>
                <th>VendorName</th>
                {/* <th>DocStatus</th> */}
                <th>activitystatus</th>
                <th>Txn_BaseValue</th>
                <th>Txn_TaxAmount</th>
                <th>Txn_WHTaxAmount</th>
                <th>Txn_PayAmount</th>
                <th>Lc_BaseValue</th>
                <th>Lc_TaxAmount</th>
                <th>Lc_WHTaxAmount</th>
                <th>Lc_PayAmount</th>
                <th>DueDate</th>
                <th>DueDateAge</th>
                <th>AlreadyPaidAmount</th>
                <th>PayableAmt</th>
                <th>NoDue</th>
                <th>0-45 Days</th>
                <th>46-90 Days</th>
                <th>91-120 Days</th>
                <th>121-180 Days</th>
                <th>Over 180 Days</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                   <td>{item.PartyLedgerName}</td>
                  <td>{item.documentnumber}</td>
                  <td>{item.documentref}</td>
                  <td>{item.documentdate}</td>
                  <td>{item.AccountingDate}</td>
                  <td>{item.ExchangeRate}</td>
                  <td>{item.transactioncurrency}</td>
                  <td>{item.DocumentType}</td>
                  <td>{item.Ledger}</td>
                  <td>{item.ForprdFrom}</td>
                  <td>{item.ForPrdTo}</td>
                  <td>{item.senderaccount}</td>
                  <td>{item.VendorName}</td>
                  {/* <td>{item.DocStatus}</td> */}
                  <td>{item.DocStatus}</td>
                  <td>{item.Txn_BaseValue}</td>
                  <td>{item.Txn_TaxAmount}</td>
                  <td>{item.Txn_WHTaxAmount}</td>
                  <td>{item.Txn_PayAmount}</td>
                  <td>{item.Lc_BaseValue}</td>
                  <td>{item.Lc_TaxAmount}</td>
                  <td>{item.Lc_WHTaxAmount}</td>
                  <td>{item.Lc_PayAmount}</td>
                  <td>{item.DueDate}</td>
                  <td>{item.DueDateAge}</td>
                  <td>{item.AlreadyPaidAmount}</td>
                  <td>{item.PayableAmt}</td>
                  <td>{item.NoDue}</td>
                  <td>{item["0-45 Days"]}</td>
                  <td>{item["46-90 Days"]}</td>
                  <td>{item["91-120 Days"]}</td>
                  <td>{item["121-180 Days"]}</td>
                  <td>{item["Over 180 Days"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : submitted && !loading && tableData.length === 0 ? (
        <div style={{display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center',alignContent:'center', marginTop:'200px'}}>No records to display</div>
      ) : null}

      <ToastContainer />
    </Fragment>
  );
};

export default PaymentReport;
