// import { useState, useEffect } from 'react';
// import '../../CI/CIPopup.css';
// import '../../CI/LARPopup.css'
// import {Modal} from 'react-bootstrap';
// import {toast, ToastContainer} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';

// const InputContainer = ({ onChange, values }) => {
//     const [lobValue, setLobValue] = useState('');
//     const [percentageValue, setPercentageValue] = useState('');

//     const handleInputChange = () => {
//         onChange({ lob: lobValue, percentage: percentageValue });
//     };

//     return (
//         <div style={{ justifyContent: "space-around", display: "flex", marginBottom: '5px' }}>
//             <select
//                 className='spacing' 
//                 name='lob'
//                 value={lobValue} 
//                 onChange={(e) => setLobValue(e.target.value)}
//                 onBlur={handleInputChange}
//             >
//                 <option value="" disabled>Select</option>
//                 {values.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//             </select>
//             <input
//                 className='spacing'
//                 type='number'
//                 name='percentage'
//                 placeholder='Percentage'
//                 value={percentageValue}
//                 onChange={(e) => setPercentageValue(e.target.value)}
//                 onBlur={handleInputChange}
//             />
//         </div>
//     );
// }

// const CostContributionPopup = (props) => {
//     console.log(props.lobs)

//     const [inputValues, setInputValues] = useState([])

//     const [dropdown, setDropdown] = useState(props.lobs)

//     const handleInputChange = (index, data) => {
//         const newInputValues = [...inputValues];
//         newInputValues[index] = data;
//         setInputValues(newInputValues);
//     };

//     const handleSubmit = () => {
//         const sum = inputValues.reduce((total, currentValue) => {
//             return total + parseInt(currentValue.percentage || 0);
//         }, 0);

//         if(sum !== 100){
//             toast.error("Total percentage doesn't add up to 100%")
//         } else {
//             props.handleData(inputValues)
//         }
//     }

//     return(
//         <div>
//             <Modal show={true} onHide={false} backdrop='static'>
//                 <Modal.Header>
//                     <Modal.Title style={{fontSize:'1rem'}}>Please enter the LOB and their percentage</Modal.Title>
//                 </Modal.Header>

//                 <Modal.Body>
//                     <div>
//                         <div style={{display:"flex", justifyContent:"space-around"}}>
//                             <p>LOB</p>
//                             <p>Percentage</p>
//                         </div>
//                         <div>
//                             {[...Array(10)].map((_, index) => (
//                                 <InputContainer values={dropdown} key={index} onChange={(data) => handleInputChange(index, data)} />
//                             ))}
//                         </div>
//                     </div>
//                 </Modal.Body>

//                 <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
//                     <button className='btn green_button' onClick={handleSubmit}>Submit</button>
//                     <button className='btn red_button' onClick={props.handleClose}>Close</button>
//                 </Modal.Footer>
//             </Modal>

//             <ToastContainer/>
//         </div>
//     )
// }

// export default CostContributionPopup


import { useState, useEffect,useMemo  } from 'react';
import '../../CI/CIPopup.css';
import '../../CI/LARPopup.css'
import {Modal} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
//start
const InputContainer = ({ onChange, values, isPercentageMode, initialLob, initialInput, initialInputPercentage,totalValue, selectedLobs, updateSelectedLobs  }) => {
    const [lobValue, setLobValue] = useState(initialLob || '');
    const [inputValue, setInputValue] = useState(initialInput || '');
    const [percentageValue, setPercentageValue] = useState(initialInputPercentage ||'');
    console.log(lobValue);
    console.log(inputValue);

    useEffect(() => {
        setLobValue(initialLob || '');
        setInputValue(initialInput || '');
        setPercentageValue(initialInputPercentage || '');

        console.log(lobValue)
        console.log(percentageValue)

    console.log(inputValue);
    }, [initialLob, initialInput,initialInputPercentage]);





    const handleInputChange = (lob, input,amountPercentage) => {
        onChange({ 
            lob: lob,
            amount: !isPercentageMode ? parseFloat(input) : undefined,
            percentage: isPercentageMode ? parseInt(input, 10) : undefined,
            amountPercentage:amountPercentage
        });
    };

    const filteredValues = useMemo(() => {
        return values.filter(item =>
            item.toLowerCase().includes(lobValue.toLowerCase()) && !selectedLobs.includes(item)
        ).sort();
    }, [lobValue, values, selectedLobs]);

    const handleLobChange = (e) => {
        const newLob = e.target.value;
        setLobValue(newLob);

        updateSelectedLobs(lobValue, newLob);


        handleInputChange(newLob, inputValue, percentageValue);
    };

  

    const handleAmountChange = (e) => {
        let lobAmount = e.target.value;
        let amountPercentage = (lobAmount/totalValue * 100).toFixed(4)
        console.log(amountPercentage)
        setPercentageValue(amountPercentage)
       
        setInputValue(lobAmount);
        handleInputChange(lobValue, lobAmount,amountPercentage);
    };

    return (
        <div style={{ justifyContent: "space-around", display: "flex", marginBottom: '3px' }}>
            <input
                className='spacing' 
                type='text'
                name='lob'
                value={lobValue}
                placeholder="LOB"
                onChange={handleLobChange}
                list={`lob-options-${lobValue}`}
            />
            <datalist id={`lob-options-${lobValue}`}>
                {filteredValues.map((item, index) => (
                    <option key={index} value={item} />
                ))}
            </datalist>
            <input
                className='spacing'
                type='number'
                name={isPercentageMode ? 'percentage' : 'amount'}
                placeholder={isPercentageMode ? 'Percentage' : 'Enter Value'}
                value={inputValue}
                onChange={handleAmountChange}
                style={{marginLeft:'10px'}}
            />
           {!isPercentageMode && (
        <input
            className='spacing'
            type='number'
            name='percentcalculator'
            placeholder='Percentage'
            value={percentageValue}
            // onChange={handleAmountChange}
            style={{ marginLeft: '10px' }}
            disabled
        />
    )}
        </div>
    );
}





const CostContributionPopup = (props) => {
    console.log(props.lobs)
    console.log(props.totalValue)

    const [inputValues, setInputValues] = useState([])
    const [totalvalue,setTotalvalue]= useState([props.totalValue])

    const [dropdown, setDropdown] = useState(Array.isArray(props.lobs)?props.lobs : [] );
    const [selectedLobs, setSelectedLobs] = useState([]);
    const isPercentageMode = props.mode === 'percentage';




    useEffect(() => {
        if (props.preFillValues && props.preFillValues.length > 0) {

            const parsedValues = props.preFillValues.map(value => {
                const [lob, input,amountPercentage] = value.split(':');
                return {
                    lob: lob,
                    amount: !isPercentageMode ? parseFloat(input) : undefined,
                    percentage: isPercentageMode ? parseInt(input, 10) : undefined,
                    amountPercentage:amountPercentage
                };
            });
            setInputValues(parsedValues);
        } else {
            setInputValues([]);
        }
    }, [props.preFillValues, isPercentageMode]);


    const handleInputChange = (index, data) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = data;
        setInputValues(newInputValues);
    };


    const updateSelectedLobs = (prevLob, newLob) => {
        setSelectedLobs((prevSelectedLobs) => {
            const updatedLobs = prevSelectedLobs.filter(lob => lob !== prevLob);
            if (newLob) {
                updatedLobs.push(newLob);
            }
            return updatedLobs;
        });
    };

    const handleSubmit = () => {

        

        if (isPercentageMode) {


            for (let i = 0; i < inputValues.length; i++) {
                const { lob} = inputValues[i];
                
                if (!lob) {
                    toast.error(`LOB can't be empty in row ${i + 1}`);
                    return;
                }
        
                   }


            console.log("percentage mode  on submit")
            const sum = inputValues.reduce((total, currentValue) => {
                return total + parseInt(currentValue.percentage  || 0);
            }, 0);

            if (sum !== 100) {
                toast.error("Total percentage doesn't add up to 100%");
                return;
            }
        }
if(!isPercentageMode){

    for (let i = 0; i < inputValues.length; i++) {
        const { lob } = inputValues[i];
        if (!lob) {
            toast.error(`LOB can't be empty in row ${i + 1}`);
            return;
        }
    }

    console.log("percentage mode on submit");
    console.log(inputValues);
    

    const allocatedSum = inputValues.reduce((total, currentValue) => {
        const amountPercentage = parseFloat(currentValue.amountPercentage || 0); // Use amountPercentage here
        return total + (amountPercentage / 100) * totalvalue; // Calculate allocated amount based on totalvalue
    }, 0);
    console.log(allocatedSum);

    const unallocatedAmount = totalvalue - allocatedSum;
    console.log(unallocatedAmount);
    

    // Allow a small tolerance for rounding issues
    if (Math.abs(unallocatedAmount) > 0.01) {
        toast.error(`There is an unallocated amount of ${unallocatedAmount.toFixed(2)} remaining.`);
        return;
    }

    // const sum = inputValues.reduce((total, currentValue) => {
    //     return total + parseInt(currentValue.percentage || 0);
    // }, 0);

    // console.log(sum);
    

    // if (sum !== 100) {
    //     toast.error("Total percentage doesn't add up to 100%");
    //     return;
    // }
}
        // You can add additional checks for total amount mode if needed
        props.handleData(inputValues);
    }

    return(
        <div>
            <Modal show={true} onHide={props.handleClose} backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title style={{fontSize:'1rem'}}>{isPercentageMode ? 'Please enter the LOB and their percentage' : 'Please enter the LOB and their amount'} </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div style={{display:"flex", justifyContent:"space-around"}}>
                            <p>Lob</p>
                            <p>{isPercentageMode ? 'Percentage' : 'Amount'}</p>
                            {!isPercentageMode ? <p>Percentage</p> : null}
                        </div>
                        <div>
                            {[...Array(8)].map((_, index) => (
                                <InputContainer values={dropdown} key={index} onChange={(data) => handleInputChange(index, data)} isPercentageMode={isPercentageMode}  initialLob={inputValues[index]?.lob}  initialInput={isPercentageMode ? inputValues[index]?.percentage : inputValues[index]?.amount}  initialInputPercentage ={inputValues[index]?.amountPercentage}   totalValue={totalvalue} selectedLobs={selectedLobs} updateSelectedLobs={updateSelectedLobs} />
                            ))}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{display:'flex', justifyContent:'center'}}>
                    <button className='btn green_button' onClick={handleSubmit}>Submit</button>
                    {/* <button className='btn red_button' onClick={props.handleClose}>Close</button> */}
                </Modal.Footer>
            </Modal>

            <ToastContainer/>
        </div>
    )
}

export default CostContributionPopup










