import React, { Component, Fragment } from 'react';
import { getHumanReadableDate, getRequest, postRequest, filterDatesByRange, formatDate, sortDatesAscending } from '../../globalhelper/helper';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import axios from 'axios';
import ApiConstants from '../../config'
import download from 'downloadjs';
import PaginationEmail from './PaginationEmail';
import Popup from '../../components/SimplePOPUP';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faTrash, faBook, faEnvelope, faPaperclip, faCheckCircle, faTimesCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';


export class FetchEmail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clickedRow: null,
            emails: [],
            filename: '',
            fileData: '',
            data: [],
            resetData: [],
            fetching: false,
            currentPage: 1,
            postsPerPage: 20,
            tableDisplay: [],
            filterTable: [],
            filterDisplay: [],
            currentPage1: 1,
            postsPerPage1: 20,
            show_maintable: false,
            show_filterTable: false,
            show_pdf: false,
            formData: {
                FromDate: '',
                ToDate: '',
                Date: '',
                From: '',
                Subject: '',
                Attachment: '',
                Status: '',
                VendorName: '',
                InvoiceNumber: '',
                InvoiceDate: '',
                BillNumber: '',
                DocumentNumber: '',
                Tag: '',
                transactionstatus: '',
                UploadMode: '',
                description: '',
                integrationStatus: ''
            },

            pdf_data: '',
            selectedItem: null,
            pdfDescription: '',

            dropdown_date: [],
            dropdown_from: [],
            dropdown_subject: [],
            dropdown_attachment: [],
            dropdown_status: [],
            dropdown_vendorname: [],
            dropdown_invoicenumber: [],
            dropdown_invoicedate: [],
            dropdown_billnumber: [],
            dropdown_documentnumber: [],
            dropdown_tag: [],
            dropdown_documentstatus: [],
            dropdown_UploadMode: [],
            dropdown_integrationStatus: []
        }
    }

    getFilteredArray = (value, key) => {
        let dropdown_array = value.map((value) => value[key])
        let filtered_dropdown_array = [... new Set(dropdown_array)]
        return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            fetching: true
        })
        const orgid = this.props.company
        console.log(orgid)

        // New Code to filter out the bill numbers pertaining to interimid

        console.log(this.props.value)

        const interimNumbers = this.getFilteredArray(this.props.value, "BillNumber")
        console.log(interimNumbers)

        const request = {
            orgid,
            interimNumbers
        }
        postRequest(`api/edureka/getBillTransactionStatus`, request).then(value => {
            let arr1 = this.props.value;
            let arr2 = value.res.data;
        
            console.log(arr1);
            console.log(arr2);
        
            const integrationStatus = {
                "integrationStatus": "Nil"
            };
        
            // Merge duplicates in arr1 based on BillNumber
            const mergedArr1 = Object.values(
                arr1.reduce((acc, item) => {
                    if (!acc[item.BillNumber]) {
                        acc[item.BillNumber] = { ...item };
                    } else {
                        // Merge duplicate entries into one
                        acc[item.BillNumber] = { ...acc[item.BillNumber], ...item };
                    }
                    return acc;
                }, {})
            );
        
            // Combine mergedArr1 with arr2
            const combinedArray = mergedArr1.map(item => {
                const matched = arr2.find(val => val.BillNumber === item.BillNumber);
                if (matched) {
                    return { ...item, ...integrationStatus, ...matched };
                } else {
                    return { ...item, ...integrationStatus };
                }
            });
        
            console.log(combinedArray);
        
            let filtered_dropdown_date = this.getFilteredArray(combinedArray, "Date");
            let filtered_dropdown_from = this.getFilteredArray(combinedArray, "From");
            let filtered_dropdown_subject = this.getFilteredArray(combinedArray, "Subject");
            let filtered_dropdown_attachment = this.getFilteredArray(combinedArray, "Attachment");
            let filtered_dropdown_status = this.getFilteredArray(combinedArray, "Status");
            let filtered_dropdown_vendorname = this.getFilteredArray(combinedArray, "VendorName");
            let filtered_dropdown_invoicenumber = this.getFilteredArray(combinedArray, "InvoiceNumber");
            let filtered_dropdown_invoicedate = this.getFilteredArray(combinedArray, "InvoiceDate");
            let filtered_dropdown_billnumber = this.getFilteredArray(combinedArray, "BillNumber");
            let filtered_dropdown_documentnumber = this.getFilteredArray(combinedArray, "DocumentNumber");
            let filtered_dropdown_tag = this.getFilteredArray(combinedArray, "Tag");
            let filtered_dropdown_documentstatus = this.getFilteredArray(combinedArray, "transactionstatus");
            let filtered_dropdown_integrationstatus = this.getFilteredArray(combinedArray, "integrationStatus");
            let filtered_dropdown_UploadMode = this.getFilteredArray(combinedArray, "UploadMode");
        
            this.setState(
                {
                    ...this.state,
                    fetching: false,
                    show_maintable: true,
                    emails: combinedArray,
                    resetData: combinedArray,
                    dropdown_date: filtered_dropdown_date,
                    dropdown_from: filtered_dropdown_from,
                    dropdown_subject: filtered_dropdown_subject,
                    dropdown_attachment: filtered_dropdown_attachment,
                    dropdown_status: filtered_dropdown_status,
                    dropdown_vendorname: filtered_dropdown_vendorname,
                    dropdown_invoicenumber: filtered_dropdown_invoicenumber,
                    dropdown_invoicedate: filtered_dropdown_invoicedate,
                    dropdown_billnumber: filtered_dropdown_billnumber,
                    dropdown_documentnumber: filtered_dropdown_documentnumber,
                    dropdown_tag: filtered_dropdown_tag,
                    dropdown_documentstatus: filtered_dropdown_documentstatus,
                    dropdown_UploadMode: filtered_dropdown_UploadMode,
                    dropdown_integrationStatus: filtered_dropdown_integrationstatus
                },
                () => this.handlePagination()
            );
        }).catch(err => {
            console.log(err);
            this.setState({
                ...this.state,
                fetching: false
            });
        })

        // postRequest(`api/edureka/getBillTransactionStatus`, request).then(value => {
        //     let arr1 = this.props.value
        //     let arr2 = value.res.data

        //     console.log(arr1)
        //     console.log(arr2)

        //     const integrationStatus = {
        //         "integrationStatus": "Nil"
        //     }

        //     const combinedArray = arr1.map(item => {
        //         if (arr2.length) {
        //             const matched = arr2.find(val => val.BillNumber === item.BillNumber)
        //             if (matched) {
        //                 return { ...item, ...integrationStatus, ...matched }
        //             } else {
        //                 return {...item, ...integrationStatus} 
        //             }
        //         } else {
        //             return { ...item, ...integrationStatus}
        //         }
        //     })

        //     console.log(combinedArray)

        //     let filtered_dropdown_date = this.getFilteredArray(arr1, "Date");
        //     let filtered_dropdown_from = this.getFilteredArray(arr1, "From");
        //     let filtered_dropdown_subject = this.getFilteredArray(arr1, "Subject");
        //     let filtered_dropdown_attachment = this.getFilteredArray(arr1, "Attachment");
        //     let filtered_dropdown_status = this.getFilteredArray(arr1, "Status");
        //     let filtered_dropdown_vendorname = this.getFilteredArray(arr1, "VendorName");
        //     let filtered_dropdown_invoicenumber = this.getFilteredArray(arr1, "InvoiceNumber");
        //     let filtered_dropdown_invoicedate = this.getFilteredArray(arr1, "InvoiceDate");
        //     let filtered_dropdown_billnumber = this.getFilteredArray(arr1, "BillNumber");
        //     let filtered_dropdown_documentnumber = this.getFilteredArray(arr2, "DocumentNumber")
        //     let filtered_dropdown_tag = this.getFilteredArray(arr1, "Tag")
        //     let filtered_dropdown_documentstatus = this.getFilteredArray(arr2, "transactionstatus")
        //     let filtered_dropdown_integrationstatus = this.getFilteredArray(arr2, "integrationStatus")
        //     let filtered_dropdown_UploadMode = this.getFilteredArray(arr1, "UploadMode")

        //     this.setState({
        //         ...this.state,
        //         fetching: false,
        //         show_maintable: true,
        //         emails: combinedArray,
        //         resetData: combinedArray,
        //         dropdown_date: filtered_dropdown_date,
        //         dropdown_from: filtered_dropdown_from,
        //         dropdown_subject: filtered_dropdown_subject,
        //         dropdown_attachment: filtered_dropdown_attachment,
        //         dropdown_status: filtered_dropdown_status,
        //         dropdown_vendorname: filtered_dropdown_vendorname,
        //         dropdown_invoicenumber: filtered_dropdown_invoicenumber,
        //         dropdown_invoicedate: filtered_dropdown_invoicedate,
        //         dropdown_billnumber: filtered_dropdown_billnumber,
        //         dropdown_documentnumber: filtered_dropdown_documentnumber,
        //         dropdown_tag: filtered_dropdown_tag,
        //         dropdown_documentstatus: filtered_dropdown_documentstatus,
        //         dropdown_UploadMode: filtered_dropdown_UploadMode,
        //         dropdown_integrationStatus: filtered_dropdown_integrationstatus
        //     }, () => this.handlePagination())
        // }).catch(err => {
        //     console.log(err)
        //     this.setState({
        //         ...this.state,
        //         fetching: false
        //     })
        // })
    }

    downloadAttachment = async (data, fileName) => {
        console.log(data)
        console.log(fileName)
        try {
            const blob = new Blob([Buffer.from(data)], { type: data.type })
            const link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
        } catch (error) {
            console.log(error)
        }
    }

    // handleDownload = (email) => {
    //     this.setState({
    //         ...this.state,
    //         filename: email.Attachment,
    //         fileData: email.Data.data
    //     }, () => {
    //         this.downloadAttachment(this.state.fileData, this.state.filename)
    //     })
    // }

    handleDocument = (item) => {
        const orgid = this.props.company
        getRequest(`api/edureka/getBillJSON?key=${item.DocumentNumber}&orgid=${orgid}`).then(response => {
            this.setState({
                redirectBill: true,
                SearchJSON: response.res.data
            })
        })
    }

    handleBill = (item) => {
        getRequest(`api/edureka/getJSONViaKey?key=${item.BillNumber}&companyid=${this.props.company}`).then(response => {
            if (response.res.status === "success") {
                console.log(response.res.data)
                this.setState({
                    redirectBill: true,
                    SearchJSON: response.res.data
                })
            }
        })
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        let currentPosts = this.customSort(this.state.emails).slice(indexofFirstPost, indexOfLastPost)
        console.log(currentPosts)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    handlePagination1 = () => {
        let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
        let currentPosts = this.customSort(this.state.filterTable).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            filterDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    paginate1 = (pageNumber) => {
        this.setState({
            ...this.state,
            currentPage1: pageNumber
        }, () => this.handlePagination1())
    }

    handleS3Download = (item) => {
        const fileName = item.Attachment
        const Date = item.CurrentDate
        const UploadModeFE = item.UploadMode === "Manual Upload" ? true : false
        const orgid = this.props.company

        postRequest(`api/edureka/getInboxPDF`, { file: fileName, date: Date, UploadModeFE: UploadModeFE, orgid: orgid }).then(response => {
            this.setState({
                ...this.state,
                pdf_data: response.res.data,
                show_pdf: true,
                selectedItem: item,
                pdfDescription: item.description || ''
            })
        })
        // axios.post(ApiConstants.externals.serverUrl + `api/edureka/downloadBills`, {filename: fileName, date: Date},
        // {responseType: 'blob'}).then(response => {
        //     const blob = response.data;
        //     this.setState({
        //         ...this.state,
        //         pdf_data: response.data,
        //         show_pdf: true
        //     })
        //     // download(blob, item.Attachment)
        // })
    }

    //updates the  textarea value to the state in the pdf popup
    handlePdfDescriptionChange = (e) => {
        this.setState({ 
            ...this.state,
            pdfDescription: e.target.value })
    }

    //to update the value of the textarea to the dynamo DB
    handlePdfDescriptionSave = () => {

        const array1 = this.state.emails
        const array2 = this.state.tableDisplay
        const array3 = this.state.filterTable

        const objIndex1 = array1.findIndex(obj => obj.SK === this.state.selectedItem.SK)
        const objIndex2 = array2.findIndex(obj => obj.SK === this.state.selectedItem.SK)
        const objIndex3 = array3.findIndex(obj => obj.SK === this.state.selectedItem.SK)
        console.log(objIndex1)
        console.log(objIndex2)
        console.log(objIndex3)

        if (this.state.pdfDescription) {

            const update11 = [array1[objIndex1]].map(obj => {
                if (obj.transactionstatus === undefined) {
                    const { transactionstatus, ...rest } = obj
                    return rest
                } else {
                    return obj
                }
            })

            if (objIndex2 !== -1) {
                const update12 = [array2[objIndex2]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update2 = { ...update12[0], description: this.state.pdfDescription }
                console.log(update2)

                let updatedArray2 = [...array2.slice(0, objIndex2), update2, ...array2.slice(objIndex2 + 1)]
                console.log(updatedArray2)
            }

            if (objIndex3 !== -1) {
                const update13 = [array3[objIndex3]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update3 = { ...update13[0], description: this.state.pdfDescription }
                console.log(update3)

                let updatedArray3 = [...array3.slice(0, objIndex3), update3, ...array3.slice(objIndex3 + 1)]
                console.log(updatedArray3)

                this.setState({
                    ...this.state,
                    updatedArray: updatedArray3
                })
            }

            const update1 = { ...update11[0], description: this.state.pdfDescription }
            console.log(update1)
            const updatedArray1 = [...array1.slice(0, objIndex1), update1, ...array1.slice(objIndex1 + 1)]
            console.log(updatedArray1)

            postRequest(`api/edureka/handleTagging`, { item: update1 }).then(res => {

                if (this.state.filterTable.length) {
                    console.log('Entered')
                    console.log(this.state.updatedArray)
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        resetData: updatedArray1,
                        filterTable: this.state.updatedArray
                    }, () => this.handlePagination1())
                } else {
                    console.log('E')
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        // tableDisplay: updatedArray1,
                        resetData: updatedArray1,
                        // filterDisplay: updatedArray3 === [] ? [] : updatedArray3
                    }, () => this.handlePagination())
                }
            })
        }

    }

    //function to open the text area
    handleEdit = (index) => {
        let updateddata = [...this.state.tableDisplay];
        updateddata[index] = { ...updateddata[index], showtextarea: !updateddata[index].showtextarea };
        this.setState({
            ...this.state,
            tableDisplay: updateddata });
        console.log(updateddata);
    }


//function to open the textarea in the filtered table  and allow to edit the additional input
    handleFilterTableEdit = (index) => {
        let updateddata = [...this.state.filterDisplay];
        updateddata[index] = { ...updateddata[index], showtextarea: !updateddata[index].showtextarea };
        this.setState({ 
            ...this.state,
            filterDisplay: updateddata });
        console.log(updateddata);
    }

    //uploads the aditionalInput data to the dynamoDB
    handleDescriptionChange = (item, e) => {

        console.log(e.target.value)
        let updatedArray2 = []
        let updatedArray3 = []

        const array1 = this.state.emails
        const array2 = this.state.tableDisplay
        const array3 = this.state.filterTable

        const objIndex1 = array1.findIndex(obj => obj.SK === item.SK)
        const objIndex2 = array2.findIndex(obj => obj.SK === item.SK)
        const objIndex3 = array3.findIndex(obj => obj.SK === item.SK)
        console.log(objIndex1)
        console.log(objIndex2)
        console.log(objIndex3)

        if (e.target.value) {

            const update11 = [array1[objIndex1]].map(obj => {
                if (obj.transactionstatus === undefined) {
                    const { transactionstatus, ...rest } = obj
                    return rest
                } else {
                    return obj
                }
            })

            if (objIndex2 !== -1) {
                const update12 = [array2[objIndex2]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update2 = { ...update12[0], description: e.target.value }
                console.log(update2)

                let updatedArray2 = [...array2.slice(0, objIndex2), update2, ...array2.slice(objIndex2 + 1)]
                console.log(updatedArray2)
            }

            if (objIndex3 !== -1) {
                const update13 = [array3[objIndex3]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update3 = { ...update13[0], description: e.target.value }
                console.log(update3)

                let updatedArray3 = [...array3.slice(0, objIndex3), update3, ...array3.slice(objIndex3 + 1)]
                console.log(updatedArray3)

                this.setState({
                    ...this.state,
                    updatedArray: updatedArray3
                })
            }

            const update1 = { ...update11[0], description: e.target.value }
            console.log(update1)
            const updatedArray1 = [...array1.slice(0, objIndex1), update1, ...array1.slice(objIndex1 + 1)]
            console.log(updatedArray1)

            postRequest(`api/edureka/handleTagging`, { item: update1 }).then(res => {

                if (this.state.filterTable.length) {
                    console.log('Entered')
                    console.log(this.state.updatedArray)
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        resetData: updatedArray1,
                        filterTable: this.state.updatedArray
                    }, () => this.handlePagination1())
                } else {
                    console.log('E')
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        // tableDisplay: updatedArray1,
                        resetData: updatedArray1,
                        // filterDisplay: updatedArray3 === [] ? [] : updatedArray3
                    }, () => this.handlePagination())
                }
            })
        }
    }

    //deletes the data of the additional Input from the dynomoDB
    handleDescriptionDelete = (item) => {
        const array1 = this.state.emails;
        const array2 = this.state.tableDisplay;
        const array3 = this.state.filterTable;

        const objIndex1 = array1.findIndex(obj => obj.SK === item.SK);
        const objIndex2 = array2.findIndex(obj => obj.SK === item.SK);
        const objIndex3 = array3.findIndex(obj => obj.SK === item.SK);

        // Clear description
        if (objIndex1 !== -1) {
            array1[objIndex1].description = '';
        }

        if (objIndex2 !== -1) {
            array2[objIndex2].description = '';
        }

        if (objIndex3 !== -1) {
            array3[objIndex3].description = '';
        }

        this.setState({
            ...this.state,
            emails: array1,
            tableDisplay: array2,
            filterTable: array3,
        }, () => {
            postRequest(`api/edureka/handleTagging`, { item: { ...item, description: '' } })
                .then(res => {
                    this.handlePagination();
                });
        });

        item.showtextarea = false;
    }

    handleReCompute = (item) => {
        const orgid = this.props.company
        const companyName = this.props.companyName
        this.setState({ clickedRow: item.SK });
        postRequest(`api/edureka/handleReCompute`, { item: item, orgid: orgid, orgname: companyName }).then(res => {
            // window.location.reload()
            console.log(res)
        })
    }

    customSort = (dateStrings) => {

        const order = {
            "Processed": 0,
            "Duplicate Invoice": 1,
            "Not Processed": 2
        }

        return dateStrings.sort((a, b) => {
            const statusComparison = order[a.Status] - order[b.Status]

            if (statusComparison !== 0) {
                return statusComparison
            }

            return new Date(a.Date) - new Date(b.Date)
        })

    }

    handleFieldChange = (fieldName, fieldValue) => {
        const val = fieldValue.target.value
        console.log(val)
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [fieldName]: val
            }
        }, () => this.handleFilter(fieldName))
    }

    handleFilter = (fieldName) => {
        let Array = []

        let from = [this.state.formData.From]
        let attachment = [this.state.formData.Attachment]
        let status = [this.state.formData.Status]
        let uploadmode = [this.state.formData.UploadMode]
        let vendorname = [this.state.formData.VendorName]
        let invoicenumber = [this.state.formData.InvoiceNumber]
        let billnumber = [this.state.formData.BillNumber]
        let documentnumber = [this.state.formData.DocumentNumber]
        let tag = [this.state.formData.Tag]
        let documentstatus = [this.state.formData.transactionstatus]
        let integrationstatus = [this.state.formData.integrationStatus]
        let data = this.state.emails

        if (!from[0]) {
            from = [...this.state.dropdown_from]
        }

        if (!attachment[0]) {
            attachment = [...this.state.dropdown_attachment]
        }

        if (!status[0]) {
            status = [...this.state.dropdown_status]
        }

        if (!uploadmode[0]) {
            uploadmode = [...this.state.dropdown_UploadMode]
        }

        if (!vendorname[0]) {
            vendorname = [...this.state.dropdown_vendorname]
            vendorname.push('', undefined)
        }

        if (!invoicenumber[0]) {
            invoicenumber = [...this.state.dropdown_invoicenumber]
            invoicenumber.push('', undefined)
        }

        if (!billnumber[0]) {
            billnumber = [...this.state.dropdown_billnumber]
            billnumber.push('', undefined)
        }

        if (!documentnumber[0]) {
            documentnumber = [...this.state.dropdown_documentnumber]
            documentnumber.push('', undefined)
        }

        if (!tag[0]) {
            tag = [...this.state.dropdown_tag]
            tag.push('', undefined)
        }

        if (!documentstatus[0]) {
            documentstatus = [...this.state.dropdown_documentstatus]
            documentstatus.push('', undefined)
        }

        if(!integrationstatus[0]){
            integrationstatus = [...this.state.dropdown_integrationStatus]
            integrationstatus.push('Nil')
        }

        for (const [i, item] of data.entries()) {
            if (from.includes(item.From) && attachment.includes(item.Attachment) && status.includes(item.Status) && uploadmode.includes(item.UploadMode) && vendorname.includes(item.VendorName) && invoicenumber.includes(item.InvoiceNumber) && billnumber.includes(item.BillNumber) && documentnumber.includes(item.DocumentNumber) && tag.includes(item.Tag) &&
                ((documentstatus[0] === " " && !item.transactionstatus) || documentstatus.includes(item.transactionstatus)) 
                && 
                integrationstatus.includes(item.integrationStatus)

            ) {
                Array.push(item)
            }

            if (i === data.length - 1) {
                console.log(Array)
                this.setState({
                    ...this.state,
                    show_maintable: false,
                    show_filterTable: true,
                    currentPage1: 1,
                    filterTable: Array.length ? Array : []
                }, () => this.handlePagination1())
            }
        }

    }

    handleDateChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => {
            if (NAME === 'ToDate' && this.state.formData.FromDate) {
                const val = filterDatesByRange(this.state.dropdown_date, this.state.formData.FromDate, this.state.formData.ToDate)
                console.log(val)

                if (val.length) {
                    console.log("Entered")
                    let Array = []

                    this.state.emails.forEach(item => {
                        if (val.includes(item.Date))
                            Array.push(item)
                    })

                    this.setState({
                        ...this.state,
                        show_maintable: false,
                        show_filterTable: true,
                        currentPage1: 1,
                        filterTable: Array.length ? Array : []
                    }, () => this.handlePagination1())
                } else {
                    this.setState({
                        ...this.state,
                        show_maintable: false,
                        show_filterTable: true,
                        currentPage1: 1,
                        filterTable: []
                    }, () => this.handlePagination1())
                }
            }
        })
    }

    handleTagChange = (item, e) => {

        console.log(e.target.value)
        let updatedArray2 = []
        let updatedArray3 = []

        const array1 = this.state.emails
        const array2 = this.state.tableDisplay
        const array3 = this.state.filterTable

        const objIndex1 = array1.findIndex(obj => obj.SK === item.SK)
        const objIndex2 = array2.findIndex(obj => obj.SK === item.SK)
        const objIndex3 = array3.findIndex(obj => obj.SK === item.SK)
        console.log(objIndex1)
        console.log(objIndex2)
        console.log(objIndex3)

        if (e.target.value) {

            const update11 = [array1[objIndex1]].map(obj => {
                if (obj.transactionstatus === undefined) {
                    const { transactionstatus, ...rest } = obj
                    return rest
                } else {
                    return obj
                }
            })

            if (objIndex2 !== -1) {
                const update12 = [array2[objIndex2]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update2 = { ...update12[0], Tag: e.target.value }
                console.log(update2)

                let updatedArray2 = [...array2.slice(0, objIndex2), update2, ...array2.slice(objIndex2 + 1)]
                console.log(updatedArray2)
            }

            if (objIndex3 !== -1) {
                const update13 = [array3[objIndex3]].map(obj => {
                    if (obj.transactionstatus === undefined) {
                        const { transactionstatus, ...rest } = obj
                        return rest
                    } else {
                        return obj
                    }
                })

                let update3 = { ...update13[0], Tag: e.target.value }
                console.log(update3)

                let updatedArray3 = [...array3.slice(0, objIndex3), update3, ...array3.slice(objIndex3 + 1)]
                console.log(updatedArray3)

                this.setState({
                    ...this.state,
                    updatedArray: updatedArray3
                })
            }

            const update1 = { ...update11[0], Tag: e.target.value }
            console.log(update1)
            const updatedArray1 = [...array1.slice(0, objIndex1), update1, ...array1.slice(objIndex1 + 1)]
            console.log(updatedArray1)

            postRequest(`api/edureka/handleTagging`, { item: update1 }).then(res => {

                if (this.state.filterTable.length) {
                    console.log('Entered')
                    console.log(this.state.updatedArray)
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        resetData: updatedArray1,
                        filterTable: this.state.updatedArray
                    }, () => this.handlePagination1())
                } else {
                    console.log('E')
                    this.setState({
                        ...this.state,
                        emails: updatedArray1,
                        // tableDisplay: updatedArray1,
                        resetData: updatedArray1,
                        // filterDisplay: updatedArray3 === [] ? [] : updatedArray3
                    }, () => this.handlePagination())
                }
            })
        }
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            show_filterTable: false,
            show_maintable: true,
            currentPage: 1,
            emails: this.state.resetData,
            filterTable: [],
            formData: {
                FromDate: '',
                ToDate: '',
                Date: '',
                From: '',
                Subject: '',
                Attachment: '',
                Status: '',
                VendorName: '',
                InvoiceNumber: '',
                InvoiceDate: '',
                BillNumber: '',
                Tag: '',
                transactionstatus: '',
                DocumentNumber: '',
                UploadMode: '',
                integrationStatus: ''
            }
        }, () => this.handlePagination())
    }

    handleDownload = () => {
        this.setState({
            ...this.state
        }, () => {
            this.downloadLink.link.click()
        })
    }

    onBack = () => {
        this.setState({
            redirectBill: false,
            // redirectInterimBill: false,
            SearchJSON: []
        })
    }

    handleBack = () => {
        window.location.reload()
    }

    renderTooltip = (props, text) => (
        <Tooltip id="button-tooltip" {...props}>
          {text}
        </Tooltip>
      );

    render() {

        const { items } = this.props;

        if (this.state.redirectBill) {
            return (
                <VR2_Forms
                    screenName={"BillExtract"}
                    screenAction={"edit"}
                    From_SearchJSON={this.state.SearchJSON}
                    onBack={this.onBack}
                />
            )
        }

        else
            return (
                <>
                    <div className='row'>
                        <div className='col-md-6' align='left' style={{ alignItems: 'left', fontSize: 'x-large', fontWeight: 'bolder', fontFamily: 'sans-serif' }}>
                            Inbox
                        </div>

                        <div className='col-md-6' align='right'>
                            <Button variant='warning' className='mr-1' onClick={this.handleBack}>Back</Button>
                            <Button variant='danger' className='mr-1' onClick={this.handleReset}>Reset</Button>
                            <Button variant='success' style={{ marginRight: 10 }} onClick={this.handleDownload}>Download</Button>
                            <CSVLink data={!this.state.filterTable.length ? this.state.emails : this.state.filterTable} filename='InboxReport.csv' ref={(r) => this.downloadLink = r} target='_blank'></CSVLink>
                        </div>
                    </div>
                    <br />

                    <div className='row'>

                        <div className='col-md-2'>

                            <Form.Group>
                                <Form.Label>From</Form.Label>
                                <select className='spacing' value={this.state.formData.From} name='From' onChange={(e) => this.handleFieldChange("From", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_from.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Attachment</Form.Label>
                                <select className='spacing' value={this.state.formData.Attachment} name='Attachment' onChange={(e) => this.handleFieldChange("Attachment", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_attachment.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <select className='spacing' value={this.state.formData.Status} name='Status' onChange={(e) => this.handleFieldChange("Status", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_status.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>UploadMode</Form.Label>
                                <select className='spacing' value={this.state.formData.UploadMode} name='UploadMode' onChange={(e) => this.handleFieldChange("UploadMode", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_UploadMode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Vendor Name</Form.Label>
                                <select className='spacing' value={this.state.formData.VendorName} name='VendorName' onChange={(e) => this.handleFieldChange("VendorName", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Invoice Number</Form.Label>
                                <select className='spacing' value={this.state.formData.InvoiceNumber} name='InvoiceNumber' onChange={(e) => this.handleFieldChange("InvoiceNumber", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_invoicenumber.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Bill Number</Form.Label>
                                <select className='spacing' value={this.state.formData.BillNumber} name='BillNumber' onChange={(e) => this.handleFieldChange("BillNumber", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_billnumber.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Document No</Form.Label>
                                <select className='spacing' value={this.state.formData.DocumentNumber} name='DocumentNumber' onChange={(e) => this.handleFieldChange("DocumentNumber", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_documentnumber.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Tag</Form.Label>
                                <select className='spacing' value={this.state.formData.Tag} name='Tag' onChange={(e) => this.handleFieldChange("Tag", e)}>
                                    <option value=''>Select</option>
                                    {this.state.dropdown_tag.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Document Status</Form.Label>
                                <select className='spacing' value={this.state.formData.transactionstatus} name='transactionstatus' onChange={(e) => this.handleFieldChange("transactionstatus", e)}>
                                    <option value="">Select</option>
                                    <option value=" ">Blank</option>
                                    {this.state.dropdown_documentstatus.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className='col-md-2'>
                            <Form.Group>
                                <Form.Label>Integration Status</Form.Label>
                                <select className='spacing' value={this.state.formData.integrationStatus} name='integrationStatus' onChange={(e) => this.handleFieldChange("integrationStatus", e)}>
                                    <option value="">Select</option>
                                    <option value="Nil">Not Initiated</option>
                                    {this.state.dropdown_integrationStatus.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                    </div>
                    {this.state.show_pdf ? (
                        <Popup
                            content={
                                <Fragment>
                                    <div style={{ display: 'flex' }}>
                                        <iframe
                                            src={`data:application/pdf;base64,${this.state.pdf_data}`}
                                            title="pdf-viewer"
                                            style={{ width: '75%', height: '500px' }}
                                        ></iframe>
                                        {/* a div for the text area in the popup */}
                                        <div style={{ width: '25%', marginLeft: '10px' }}>
                                            <textarea
                                                rows={24}
                                                value={this.state.pdfDescription}
                                                onChange={this.handlePdfDescriptionChange}
                                                style={{ width: '100%', height: '85%', border: "3px solid black", color: "2px solid black" }}
                                                placeholder='Enter Description'
                                            ></textarea>
                                            <button
                                                onClick={this.handlePdfDescriptionSave}
                                                style={{
                                                    marginTop: '10px',
                                                    backgroundColor: "#233067",
                                                    borderRadius: "5px",
                                                    width: '50%',
                                                    marginLeft: "70px",
                                                    color: "white"
                                                }}>
                                                Save Description
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            handleClose={() => this.setState({ show_pdf: false })}
                        />
                    ) : null}

                    {!this.state.fetching && this.state.show_maintable && this.state.emails.length ?
                        <div className=''>
                            <table border='1' id='table-css'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>From</th>
                                        <th>Subject</th>
                                        <th style={{ maxWidth: 200 }}>Attachment</th>
                                        <th style={{ minWidth: 120 }}>Tag</th>
                                        <th>Additional Inputs</th>
                                        <th>Status</th>
                                        <th>Vendor Name</th>
                                        <th>Invoice Number</th>
                                        <th>Invoice Date</th>
                                        <th>Bill ID</th>
                                        <th>Document Number</th>
                                        <th>Document Status</th>
                                        <th>Integration Status</th>
                                        <th>ReasonForFail</th>
                                        <th style={{ minWidth: 120 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDisplay.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{formatDate(item.Date)}</td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.From)}
                                                >
                                                    <FontAwesomeIcon icon={faEnvelope} style={{color:'#233067', fontSize:'20px'}} />
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.Subject)}
                                                >
                                                    <FontAwesomeIcon icon={faBook} style={{color:'#233067', fontSize:'20px'}} />
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.Attachment)}
                                                >
                                                    <Button
                                                    style={{ maxWidth: 200, overflow: 'hidden' }}
                                                    variant='link'
                                                    size='sm'
                                                    onClick={() => this.handleS3Download(item)}
                                                    >
                                                    <FontAwesomeIcon icon={faPaperclip} style={{ fontSize:'20px'}}/>
                                                    </Button>
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                    <select value={item.Tag ? item.Tag : ''} onChange={(e) => this.handleTagChange(item, e)}>
                                                        <option value=''></option>
                                                        <option value='CSV File'>CSV File</option>
                                                        <option value='Excel File'>Excel File</option>
                                                        <option value='Valid'>Valid</option>
                                                        <option value='Void'>Void</option>
                                                        <option value='Duplicate Invoice'>Duplicate Invoice</option>
                                                        <option value='Group Company Invoice'>Group Company Invoice</option>
                                                        <option value='Clarify: Vendor/Item/CostAlloc'>Clarify: Vendor/Item/CostAlloc</option>
                                                        <option value='Clarify: Vendor'>Clarify: Vendor</option>
                                                        <option value='Clarify: Item/CostAlloc'>Clarify: Item/CostAlloc</option>
                                                        <option value='Clarify: CostAlloc'>Clarify: CostAlloc</option>
                                                        <option value='Clarify: MultiLingual'>Clarify: MultiLingual</option>
                                                        <option value='Clarify: Vendor/CostAlloc'>Clarify: Vendor/CostAlloc</option>
                                                        <option value='Clarify: tax'>Clarify: tax</option>
                                                        <option value='Clarify: vendor/item/costAlloc/tax'>Clarify: vendor/item/costAlloc/tax</option>
                                                        <option value='Clarify pan'>Clarify pan</option>
                                                        <option value='Clarify: Item'>Clarify: Item</option>
                                                        <option value='Clarify: splhandlinginvoices'>Clarify: splhandlinginvoices</option>
                                                        <option value='Clarify: TDS'>Clarify: TDS</option>
                                                        <option value='Clarify: Inventory'>Clarify: Inventory</option>
                                                        <option value='Clarified: Vendor/Item/CostAlloc'>Clarified: Vendor/Item/CostAlloc</option>
                                                        <option value='Clarified: Vendor'>Clarified: Vendor</option>
                                                        <option value='Clarified: Item/CostAlloc'>Clarified: Item/CostAlloc</option>
                                                        <option value='Clarified: CostAlloc'>Clarified: CostAlloc</option>
                                                        <option value='Clarified: MultiLingual'>Clarified: MultiLingual</option>
                                                        <option value='Clarified: Vendor/CostAlloc'>Clarified: Vendor/CostAlloc</option>
                                                        <option value='Clarified: tax'>Clarified: tax</option>
                                                        <option value='Clarified: vendor/item/costAlloc/tax'>Clarified: vendor/item/costAlloc/tax</option>
                                                        <option value='Clarified pan'>Clarified pan</option>
                                                        <option value='Clarified: Item'>Clarified: Item</option>
                                                        <option value='Clarified: splhandlinginvoices'>Clarified: splhandlinginvoices</option>
                                                        <option value='Clarified: TDS'>Clarified: TDS</option>
                                                        <option value='Clarified: Inventory'>Clarified: Inventory</option>
                                                        <option value='Sales Invoice'>Sales Invoice</option>
                                                        <option value='Incorrect Format'>Incorrect Format</option>
                                                        <option value="Incomplete VM">Incomplete VM</option>
                                                        <option value="InProgress">In Progress</option>
                                                        <option value="Reviewed">Reviewed</option>
                                                        <option value='Delivery Partners'>Delivery Partners</option>
                                                        <option value='To Be Verified'>To Be Verified</option>
                                                        <option value='Image File'>Image File</option>
                                                        <option value='Zip File'>Zip File</option>
                                                        <option value='Invalid'>Invalid</option>
                                                    </select>
                                                </td>
                                                <td>
                                                <Button style={{ backgroundColor: 'transparent', border: 'none', color: '#233067', fontSize:'15px' }} onClick={(e) => this.handleEdit(index)}><FontAwesomeIcon icon={faPenAlt} style={{ fontSize:'15px'}}/>{item.description && <span className="red-dot"></span>}</Button>
                                                    {item.showtextarea && (
                                                        <>
                                                            <textarea placeholder='Enter Description' rows={8} defaultValue={item.description || ''} onBlur={(e) => this.handleDescriptionChange(item, e)} style={{ "border": "none", "outline": "none" }}></textarea>
                                                            <button onClick={() => this.handleDescriptionDelete(item)} style={{ "border": "none", "outline": "none" }}><FontAwesomeIcon icon={faTrash} color='red' /></button>
                                                        </>

                                                    )}
                                                </td>
                                                <td>{item.Status}</td>
                                                <td>{item.VendorName}</td>
                                                <td>{item.InvoiceNumber}</td>
                                                <td>{item.InvoiceDate}</td>
                                                <td><Button variant='link' size='sm' disabled={item.transactionstatus === 'Active' || item.Tag === 'Void' || item.Tag === 'Excel File' || item.Tag === 'CSV File' || item.Tag === 'Duplicate Invoice' || item.Tag === 'Sales Invoice' || item.Tag === 'Incorrect Format'
                                                    || item.Tag === 'To Be Verified' || item.Tag === 'Image File' || item.Tag === 'Zip File' || item.transactionstatus === 'Waiting For Approval'} onClick={() => this.handleBill(item)}>{item.BillNumber}</Button></td>
                                                <td><Button variant='link' size='sm' disabled={item.transactionstatus === 'Rejected'} onClick={() => this.handleDocument(item)}>{item.DocumentNumber}</Button></td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 100, hide: 400 }}
                                                        overlay={<Tooltip id="status-tooltip">{item.transactionstatus}</Tooltip>}
                                                    >
                                                        <span>
                                                            {item.transactionstatus === 'Active' && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '20px' }} />}
                                                            {item.transactionstatus === 'Rejected' && <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '20px' }} />}
                                                            {item.transactionstatus === 'Waiting For Approval' && <FontAwesomeIcon icon={faHourglassHalf} style={{ color: 'orange', fontSize: '20px' }} />}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 100, hide: 400}}
                                                        overlay={<Tooltip id="integration-status-tooltip">{item.integrationStatus}</Tooltip>}
                                                    >
                                                        <span>
                                                            {item.integrationStatus === "Success" && <FontAwesomeIcon icon={faCheckCircle} style={{color:'green', fontSize:'20px'}} />}
                                                            {item.integrationStatus === "Failed" && <FontAwesomeIcon icon={faTimesCircle} style={{color:'red', fontSize:'20px'}} />}
                                                            {item.integrationStatus === "Initiated" && <FontAwesomeIcon icon={faHourglassHalf} style={{color:'orange', fontSize:'20px'}} />}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{item.ReasonForFailure ? item.ReasonForFailure : null}</td>
                                                <td><Button variant='success' size='sm' onClick={() => this.handleReCompute(item)} disabled={item.transactionstatus === 'Active' || item.transactionstatus === 'Waiting For Approval' || item.transactionstatus === 'Rejected' || item.Tag === 'Void' || item.Tag === 'Excel File' || item.Tag === 'CSV File' || item.Tag === 'Duplicate Invoice' || item.Tag === 'Sales Invoice' || item.Tag === 'Incorrect Format'
                                                    || item.Tag === 'To Be Verified' || item.Tag === 'Image File' || item.Tag === 'Zip File'|| this.state.clickedRow === item.SK}>Reprocess</Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> : null}

                    {this.state.show_filterTable && this.state.filterTable.length ?
                        <div className=''>
                            <table border='1' id='table-css'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>From</th>
                                        <th>Subject</th>
                                        <th style={{ maxWidth: 200 }}>Attachment</th>
                                        <th style={{ minWidth: 120 }}>Tag</th>
                                        <th>Additional Inputs</th>
                                        <th>Status</th>
                                        <th>Vendor Name</th>
                                        <th>Invoice Number</th>
                                        <th>Invoice Date</th>
                                        <th>Bill ID</th>
                                        <th>Document Number</th>
                                        <th>Document Status</th>
                                        <th>Integration Status</th>
                                        <th>ReasonForFail</th>
                                        <th style={{ minWidth: 120 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filterDisplay.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{formatDate(item.Date)}</td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.From)}
                                                >
                                                    <FontAwesomeIcon icon={faEnvelope} style={{color:'#233067', fontSize:'20px'}}/>
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.Subject)}
                                                >
                                                    <FontAwesomeIcon icon={faBook} style={{color:'#233067', fontSize:'20px'}}/>
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => this.renderTooltip(props, item.Attachment)}
                                                >
                                                    <Button
                                                    style={{ maxWidth: 200, overflow: 'hidden' }}
                                                    variant='link'
                                                    size='sm'
                                                    onClick={() => this.handleS3Download(item)}
                                                    >
                                                    <FontAwesomeIcon icon={faPaperclip} style={{fontSize:'20px'}}/>
                                                    </Button>
                                                </OverlayTrigger>
                                                </td>
                                                <td>
                                                    <select value={item.Tag ? item.Tag : ''} onChange={(e) => this.handleTagChange(item, e)}>
                                                        <option></option>
                                                        <option value='CSV File'>CSV File</option>
                                                        <option value='Excel File'>Excel File</option>
                                                        <option value='Valid'>Valid</option>
                                                        <option value='Void'>Void</option>
                                                        <option value='Duplicate Invoice'>Duplicate Invoice</option>
                                                        <option value='Group Company Invoice'>Group Company Invoice</option>
                                                        <option value='Clarify: Vendor/Item/CostAlloc'>Clarify: Vendor/Item/CostAlloc</option>
                                                        <option value='Clarify: Vendor'>Clarify: Vendor</option>
                                                        <option value='Clarify: Item/CostAlloc'>Clarify: Item/CostAlloc</option>
                                                        <option value='Clarify: CostAlloc'>Clarify: CostAlloc</option>
                                                        <option value='Clarify: MultiLingual'>Clarify: MultiLingual</option>
                                                        <option value='Clarify: Vendor/CostAlloc'>Clarify: Vendor/CostAlloc</option>
                                                        <option value='Clarify: tax'>Clarify: tax</option>
                                                        <option value='Clarify: vendor/item/costAlloc/tax'>Clarify: vendor/item/costAlloc/tax</option>
                                                        <option value='Clarify pan'>Clarify pan</option>
                                                        <option value='Clarify: Item'>Clarify: Item</option>
                                                        <option value='Clarify: splhandlinginvoices'>Clarify: splhandlinginvoices</option>
                                                        <option value='Clarify: TDS'>Clarify: TDS</option>
                                                        <option value='Clarify:Inventory'>Clarify: Inventory</option>
                                                        <option value='Clarified: Vendor/Item/CostAlloc'>Clarified: Vendor/Item/CostAlloc</option>
                                                        <option value='Clarified: Vendor'>Clarified: Vendor</option>
                                                        <option value='Clarified: Item/CostAlloc'>Clarified: Item/CostAlloc</option>
                                                        <option value='Clarified: CostAlloc'>Clarified: CostAlloc</option>
                                                        <option value='Clarified: MultiLingual'>Clarified: MultiLingual</option>
                                                        <option value='Clarified: Vendor/CostAlloc'>Clarified: Vendor/CostAlloc</option>
                                                        <option value='Clarified: tax'>Clarified: tax</option>
                                                        <option value='Clarified: vendor/item/costAlloc/tax'>Clarified: vendor/item/costAlloc/tax</option>
                                                        <option value='Clarified pan'>Clarified pan</option>
                                                        <option value='Clarified: Item'>Clarified: Item</option>
                                                        <option value='Clarified: splhandlinginvoices'>Clarified: splhandlinginvoices</option>
                                                        <option value='Clarified: TDS'>Clarified: TDS</option>
                                                        <option value='Clarified: Inventory'>Clarified: Inventory</option>
                                                        <option value='Sales Invoice'>Sales Invoice</option>
                                                        <option value='Incorrect Format'>Incorrect Format</option>
                                                        <option value="Incomplete VM">Incomplete VM</option>
                                                        <option value="InProgress">In Progress</option>
                                                        <option value="Reviewed">Reviewed</option>
                                                        <option value='Delivery Partners'>Delivery Partners</option>
                                                        <option value='To Be Verified'>To Be Verified</option>
                                                        <option value='Image File'>Image File</option>
                                                        <option value='Zip File'>Zip File</option>
                                                        <option value='Invalid'>Invalid</option>
                                                    </select>
                                                </td>
                                                <td>
                                                <Button style={{ backgroundColor: 'transparent', border: 'none', color: '#233067', fontSize:'15px' }} onClick={(e) => this.handleFilterTableEdit(index)}><FontAwesomeIcon icon={faPenAlt} style={{ fontSize:'15px'}}/>{item.description && <span className="red-dot"></span>}</Button>
                                                    {item.showtextarea && (
                                                        <>
                                                            <textarea placeholder='Enter Description' rows={8} defaultValue={item.description || ''} onBlur={(e) => this.handleDescriptionChange(item, e)} style={{ "border": "none", "outline": "none" }}></textarea>
                                                            <button onClick={() => this.handleDescriptionDelete(item)} style={{ "border": "none", "outline": "none" }}><FontAwesomeIcon icon={faTrash} color='red' /></button>
                                                        </>

                                                    )}
                                                </td>
                                                <td>{item.Status}</td>
                                                <td>{item.VendorName}</td>
                                                <td>{item.InvoiceNumber}</td>
                                                <td>{item.InvoiceDate}</td>
                                                <td><Button variant='link' size='sm' disabled={item.transactionstatus === 'Active' || item.Tag === 'Void' || item.Tag === 'Excel File' || item.Tag === 'CSV File' || item.Tag === 'Duplicate Invoice' || item.Tag === 'Sales Invoice' || item.Tag === 'Incorrect Format'
                                                    || item.Tag === 'To Be Verified' || item.Tag === 'Image File' || item.Tag === 'Zip File' || item.transactionstatus === 'Waiting For Approval'} onClick={() => this.handleBill(item)}>{item.BillNumber}</Button></td>
                                                <td><Button variant='link' size='sm' disabled={item.transactionstatus === 'Rejected'} onClick={() => this.handleDocument(item)}>{item.DocumentNumber}</Button></td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 100, hide: 400 }}
                                                        overlay={<Tooltip id="status-tooltip">{item.transactionstatus}</Tooltip>}
                                                    >
                                                        <span>
                                                            {item.transactionstatus === 'Active' && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '20px' }} />}
                                                            {item.transactionstatus === 'Rejected' && <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '20px' }} />}
                                                            {item.transactionstatus === 'Waiting For Approval' && <FontAwesomeIcon icon={faHourglassHalf} style={{ color: 'orange', fontSize: '20px' }} />}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 100, hide: 400}}
                                                        overlay={<Tooltip id="integration-status-tooltip">{item.integrationStatus}</Tooltip>}
                                                    >
                                                        <span>
                                                            {item.integrationStatus === "Success" && <FontAwesomeIcon icon={faCheckCircle} style={{color:'green', fontSize:'20px'}} />}
                                                            {item.integrationStatus === "Failed" && <FontAwesomeIcon icon={faTimesCircle} style={{color:'red', fontSize:'20px'}} />}
                                                            {item.integrationStatus === "Initiated" && <FontAwesomeIcon icon={faHourglassHalf} style={{color:'orange', fontSize:'20px'}} />}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{item.ReasonForFailure ? item.ReasonForFailure : null}</td>
                                                <td><Button variant='success' size='sm' onClick={() => this.handleReCompute(item)} disabled={item.transactionstatus === 'Active' || item.transactionstatus === 'Waiting For Approval' || item.transactionstatus === 'Rejected' || item.Tag === 'Void' || item.Tag === 'Excel File' || item.Tag === 'CSV File' || item.Tag === 'Duplicate Invoice' || item.Tag === 'Sales Invoice' || item.Tag === 'Incorrect Format'
                                                    || item.Tag === 'To Be Verified' || item.Tag === 'Image File' || item.Tag === 'Zip File' || this.state.clickedRow === item.SK}>Reprocess</Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> : null}

                    <br />

                    {this.state.fetching ? <div className='center'>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                        <div class="wave"></div>
                    </div> : null}

                    {!this.state.fetching && this.state.show_maintable && this.state.emails.length ?
                        <PaginationEmail postsPerPage={this.state.postsPerPage} totalPosts={this.state.emails.length} currentPage={this.state.currentPage} paginate={this.paginate}></PaginationEmail> : null}

                    {this.state.show_filterTable && this.state.filterTable.length ?
                        <PaginationEmail postsPerPage={this.state.postsPerPage1} totalPosts={this.state.filterTable.length} currentPage={this.state.currentPage1} paginate={this.paginate1}></PaginationEmail> : null}
                </>

            )


    }
}

export default FetchEmail